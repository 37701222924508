import { createSlice } from "@reduxjs/toolkit";
import { getList, getPatientById } from "./actions";

const initialState = {
  records: [],
  record: {},
  page: 1,
  total: 1,
  pageSize: 10,
  loading: false,
  searchLoading: false,
  isShowForm: false,
  keyword: "",
  error: "",
};

const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    filterKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    editPatient: (state, action) => {
      //console.log(action);
      state.record = action.payload;
      state.isShowForm = true;
    },
    addPatient: (state, action) => {
      state.isShowForm = true;
      state.record = {};
    },
    closeForm: (state, action) => {
      state.isShowForm = false;
    },
    setRecord: (state, action) => {
      state.record = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state) => {
        state.isShowForm = false;
        state.loading = true;
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload.records;
        state.isShowForm = false;
        state.record = {};
        state.page = action.payload.page;
        state.total = action.payload.totalElements;
      })
      .addCase(getPatientById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPatientById.fulfilled, (state, action) => {
        state.loading = false;
        state.record = action.payload.record;
      })
      .addCase(getPatientById.rejected, (state, action) => {
        state.loading = false;
        state.record = {};
      });
  },
});

const { reducer, actions } = patientSlice;
export const { editPatient, addPatient, closeForm ,filterKeyword,setRecord } = actions;
export default reducer;
