import { createAsyncThunk } from "@reduxjs/toolkit";
import {getTreatment,createTreatment} from "serviecs/treatment.serviec"
import { setMessage } from "../message/index";

export const getList = createAsyncThunk(
    "treatment/getAll",
    async (request, thunkAPI) => {
      try {
        const res = await getTreatment(request);

        return {
          record: res && res[0]
        };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
);
export const create = createAsyncThunk(
  "create/treament",
  async (request, thunkAPI) => {
    try {
      const body = new Date();
      const res = await createTreatment(request,body);
      return {
        record:  res
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
