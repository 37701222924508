import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllPages,
  createUser,
  updateUser,
} from "../../../serviecs/user.serviec";
import { setMessage } from "../message/index";
export const getList = createAsyncThunk(
  "user/getList",
  async (request, thunkAPI) => {
    const { page } = request;
    try {
      const { content, totalElements, totalPages,size } = await getAllPages(request);
      return {
        records: content || [],
        totalElements: totalElements,
        totalPages: totalPages,
        page: page,
        size: size
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const createUsers = createAsyncThunk(
  "user/createUser",
  async (request, thunkAPI) => {
    try {
      const data = await createUser(request);
      return {
        record: data,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const editUsers = createAsyncThunk(
  "user/editUsers",
  async (request, thunkAPI) => {
    try {
      const data = await updateUser(request);
      return {
        record: data,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const medicalHistories = [
  { value: "LONG_BLEEDING", lable: "Chảy máu lâu" },
  { value: "DIABETES", lable: "Tiểu đường" },
  { value: "DRUG_ALLERGY", lable: "Dị ứng thuốc" },
  { value: "INFECTIOUS_DISEASES", lable: "Truyền nhiễm" },
  { value: "HIGH_BLOOD_PRESSURE", lable: "Huyết áp cao" },
  { value: "LOW_BLOOD_PRESSURE", lable: "Huyết áp thấp" },
  { value: "MATERNITY", lable: "Thai sản" },
  { value: "RHEUMATISM", lable: "Thấp khớp" },
  { value: "HEART_DISEASE", lable: "Tim" },
  { value: "STOMACH_DISEASES", lable: "Dạ dày" },
  { value: "LIVER_DISEASES", lable: "Gan" },
  { value: "LUNG_DISEASES", lable: "Phổi" },
  { value: "NOMAL", lable: "Bình thường" },
];
