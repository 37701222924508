import moment from "moment/moment";
moment.locale('en', {
    week: {
        dow: 1
    }
});
moment.locale('en');

export const createColumn = (
    title,
    dataIndex,
    key,
    index,
    isActive = false,
    render,
    align = 'center'
) => {
    return {
        title,
        dataIndex,
        key,
        index,
        isActive,
        render,
        align,
    };
};
export const createColumnNoActive = (
    title,
    dataIndex,
    key,
    render,
    align = 'left',
) => {
    return {
        title,
        dataIndex,
        key,
        render,
        align,
    };
};
export const createColumnEdit = (
    title,
    dataIndex,
    key,
    render,
    align = 'left',
    editable = false,
    width = null,
    fixed = null
) => {
    const column = {
        title,
        dataIndex,
        key,
        render,
        align,
        editable,
    };
    if (width) {
        column.width = width;
    }
    if (fixed) {
        column.fixed = fixed;
    }
    return column;
};
export const formatNumber = (value) => new Intl.NumberFormat().format(value);

const GlobalData = {currencyText: ''};
export const toMoney = (num = 0) => {
    return (
        num
            .toFixed(1)
            .replace(/\d(?=(\d{3})+\.)/g, '$&.')
            .slice(0, -2) + GlobalData.currencyText
    );
};

export const dateTypeMap = {
    "0": 'Khoảng ngày',
    "1": 'Hôm nay',
    "2": 'Hôm qua',
    "3": 'Tuần này',
    "4": 'Tuần trước',
    "5": '7 ngày trước',
    "6": '30 ngày trước',
    "7": 'Tháng này',
    "8": 'Tháng trước',
    "9": 'Quý này',
    "10": 'Quý trước',
    "11": 'Năm này',
    "12": 'Năm trước',
};

export const reformatDateFilter = (request) => {
    const {date, dateType} = request;
    let fromDate = date != null ? moment(date[0], 'DD/MM/YYYY').startOf('date').valueOf() : moment().startOf('date').valueOf();
    let toDate = date != null ? moment(date[1], 'DD/MM/YYYY').endOf('date').valueOf() : moment().endOf('date').valueOf();
    switch (parseInt(dateType)) {
        case 1:
            fromDate = moment().startOf('date').valueOf();
            toDate = moment().endOf('date').valueOf();
            break
        case 2:
            fromDate = moment().startOf('date').subtract(1, 'days').valueOf();
            toDate = moment().endOf('date').subtract(1, 'days').valueOf();
            break
        case 3:
            fromDate = moment().startOf('week').valueOf();
            toDate = moment().endOf('week').valueOf();
            break
        case 4:
            fromDate = moment().startOf('week').subtract(1, 'weeks').valueOf();
            toDate = moment().endOf('week').subtract(1, 'weeks').valueOf();
            break
        case 5:
            fromDate = moment().startOf('date').subtract(7, 'days').valueOf();
            toDate = moment().endOf('date').valueOf();
            break
        case 6:
            fromDate = moment().startOf('date').subtract(30, 'days').valueOf();
            toDate = moment().endOf('date').valueOf();
            break;
        case 7:
            fromDate = moment().startOf('month').valueOf();
            toDate = moment().endOf('month').valueOf();
            break;
        case 8:
            fromDate = moment().startOf('month').subtract(1, 'months').startOf('month').valueOf();
            toDate = moment().endOf('month').subtract(1, 'months').endOf('month').valueOf();
            break
        case 9:
            fromDate = moment().startOf('quarter').valueOf();
            toDate = moment().endOf('quarter').valueOf();
            break
        case 10:
            fromDate = moment().startOf('quarter').subtract(1, 'quarters').valueOf();
            toDate = moment().endOf('quarter').subtract(1, 'quarters').valueOf();
            break
        case 11:
            fromDate = moment().startOf('year').valueOf();
            toDate = moment().endOf('year').valueOf();
            break
        case 12:
            fromDate = moment().startOf('year').subtract(1, 'years').valueOf();
            toDate = moment().endOf('year').subtract(1, 'years').valueOf();
            break
        default:
//            fromDate = getUnixTimeStamp(fromDate);
//            toDate = getUnixTimeStamp(toDate);
            break;
    }
    return {
        fromDate: fromDate,
        toDate: toDate
    }
}


export const snakeToCamel = (str) => {
    return str.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
            .replace('-', '')
            .replace('_', '');
    });
};