const home = [
  <svg
    viewBox="0 0 24 24"
    width="20"
    height="20"
    stroke="white"
    stroke-width="1"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
    className="css-i6dzq1"
  >
    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
    <polyline points="9 22 9 12 15 12 15 22"></polyline>
  </svg>,
];
const user = [
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#ffffffff"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-user"
  >
    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
    <circle cx="12" cy="7" r="4"></circle>
  </svg>,
];
const users = [
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#ffffffff"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-users"
  >
    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
    <circle cx="9" cy="7" r="4"></circle>
    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
  </svg>,
];
const dollarSign = [
  <svg viewBox="0 0 24 24" width="20" height="20" stroke="white" stroke-width="1" fill="none"
       stroke-linecap="round" stroke-linejoin="round" className="css-i6dzq1">
    <line x1="12" y1="1" x2="12" y2="23"></line>
    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
  </svg>
];
const gif = [
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#ffffffff"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-gift"
  >
    <polyline points="20 12 20 22 4 22 4 12"></polyline>
    <rect x="2" y="7" width="20" height="5"></rect>
    <line x1="12" y1="22" x2="12" y2="7"></line>
    <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path>
    <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path>
  </svg>,
];
const calendar = [
  <svg
    viewBox="0 0 24 24"
    width="20"
    height="20"
    stroke="white"
    stroke-width="1"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
    className="css-i6dzq1"
  >
    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
    <line x1="16" y1="2" x2="16" y2="6"></line>
    <line x1="8" y1="2" x2="8" y2="6"></line>
    <line x1="3" y1="10" x2="21" y2="10"></line>
  </svg>,
];
const book_open = [
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#ffffffff"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-book-open"
  >
    <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
    <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
  </svg>,
];
const gird = [
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#ffffffff"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-grid"
  >
    <rect x="3" y="3" width="7" height="7"></rect>
    <rect x="14" y="3" width="7" height="7"></rect>
    <rect x="14" y="14" width="7" height="7"></rect>
    <rect x="3" y="14" width="7" height="7"></rect>
  </svg>,
];
const setting = [
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#ffffffff"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-settings"
  >
    <circle cx="12" cy="12" r="3"></circle>
    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
  </svg>,
];
const pie_chart = [
  <svg viewBox="0 0 24 24" width="20" height="20" stroke="white" stroke-width="1" fill="none" stroke-linecap="round"
       stroke-linejoin="round" className="css-i6dzq1">
    <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
    <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
  </svg>
];
const help = [
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#ffffffff"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-help-circle"
  >
    <circle cx="12" cy="12" r="10"></circle>
    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
    <line x1="12" y1="17" x2="12.01" y2="17"></line>
  </svg>,
];
const layers = [
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#ffffffff"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-layers"
  >
    <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
    <polyline points="2 17 12 22 22 17"></polyline>
    <polyline points="2 12 12 17 22 12"></polyline>
  </svg>,
];
const truck = [
  <svg
    viewBox="0 0 24 24"
    width="20"
    height="20"
    stroke="white"
    stroke-width="1"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
    className="css-i6dzq1"
  >
    <rect x="1" y="3" width="15" height="13"></rect>
    <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
    <circle cx="5.5" cy="18.5" r="2.5"></circle>
    <circle cx="18.5" cy="18.5" r="2.5"></circle>
  </svg>,
];
const menuList = [
  {
    title: 'Trang chủ',
    path: '/home',
    icon: home,
    roles: ['report:general:access'],
  },
  {
    title: 'Quản lý khách hàng',
    path: '/patient',
    icon: user,
    roles: ['patient:list:access','patient:info:access'],
  },
  {
    title: 'Quản lý CSKH',
    path: '/customer-care',
    icon: gif,
    roles: ['customer:care:access', 'customer:general:access'],
  },
  {
    title: 'Thiết lập quy trình sale',
    icon: gird,
    children: [
      {
        title: 'Danh mục tác nghiệp',
        path: '/setting/pushsale/components#category',
        roles: [],
      },
      {
        title: 'Luồng tác nghiệp',
        path: '/setting/pushsale/flow',
        roles: [],
      },
    ],
    roles: ['setting:pushsale:access'],
  },
  {
    title: 'Quản lý lịch hẹn',
    path: '/appointment',
    icon: calendar,
    children: [
      {
        title: 'Danh sách lịch hẹn',
        path: '/appointment',
        roles: [],
      },
      {
        title: 'Lịch niềng',
        path: '/brace-appointment',
        roles: [],
      },
      {
        title: 'Lịch implant',
        path: '/implant-appointment',
        roles: [],
      },
    ],
    roles: ['appointment:general:access'],
  },
  {
    title: 'Quản lý nhân sự',
    path: '/user',
    icon: users,
    roles: ['user:list:access'],
  },
  {
    title: 'Quản lý hoa hồng',
    path: '/commission',
    icon: dollarSign,
    roles: ['user:commission:access'],
  },
  {
    title: 'Quản lý kho',
    icon: layers,
    children: [
      {
        title: 'Xuất nhập vật tư',
        path: '/material-dental-imex',
        roles: ['material:eximport:access'],
      },
      {
        title: 'Danh mục vật tư',
        path: '/setting/material-dental-supply',
        roles: ['material:category:access'],
      },
      {
        title: 'Quản lý tồn kho',
        path: '/material-supply-report',
        roles: ['material:eximport:access'],
      },
    ],
    roles: ['material:category:access','material:eximport:access'],
  },
  {
    title: 'Báo cáo thống kê',
    icon: pie_chart,
    children: [
      {
        title: 'Doanh thu',
        path: '/revenue-statistical',
        roles: [],
      },
      {
        title: 'Thu chi',
        path: '/revenue-expenditure',
        roles: [],
      },
      {
        title: 'Công nợ (Bệnh nhân)',
        path: '/owed-statistical',
        roles: [],
      },
      {
        title: 'Báo cáo trả góp',
        path: '/installment-report',
        roles: [],
      },
    ],
    roles: ['report:general:access'],
  },
  {
    title: 'Quản lý đặt xưởng',
    icon: truck,
    children: [
      {
        title: 'Đặt xưởng Lab Sứ',
        path: '/workshop/ceramic',
        roles: [],
      },
      {
        title: 'Đặt xưởng Lab Tháo Lắp',
        path: '/workshop/assembly',
        roles: [],
      },
    ],
    roles: ['setting:factory:access'],
  },
  {
    title: 'Cài đặt',
    icon: setting,
    children: [
      {
        title: 'Thiết lập chung',
        path: '/setting/general',
        roles: ['setting:general:access'],
      },
      {
        title: 'Phân quyền',
        path: '/permissions',
        roles: ['setting:authority:access'],
      },
      // {
      //   title: 'Hiển thị',
      //   path: '/features-package',
      //   roles: [],
      // },
      {
        title: 'Thủ thuật',
        path: '/setting/trick',
        roles: ['setting:process:access'],
      },
      {
        title: 'Thuốc',
        path: '/setting/medicine',
        roles: ['setting:medicine:access'],
      },
      {
        title: 'Xưởng / Labo',
        path: '/setting/workshop',
        roles: ['setting:factory:access'],
      },
      // {
      //   title: 'Tin nhắn',
      //   path: '/setting/customer-care',
      //   roles: [],
      // },
      // {
      //   title: 'Khuyến mãi',
      //   path: '/setting/customer',
      //   roles: [],
      // },
    ],
    roles: ['setting:general:access',
      'setting:authority:access',
      'setting:process:access',
      'setting:medicine:access',
      'setting:factory:access',
      'setting:promotion:access',],
  },
  {
    title: 'Hướng dẫn và hỗ trợ',
    path: '/help',
    icon: help,
    roles: [],
  },
];

export default menuList;
