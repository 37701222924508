import { configureStore } from '@reduxjs/toolkit';
import authReducer from './modules/auth/index';
import patientReducer from './modules/patient/index';
import userReducer from './modules/user/index';
import messageReducer from './modules/message/index';
import headerReducer from './modules/header/index';
import materialReducer from './modules/material/index';
import treatmentReducer from './modules/treatment/index';
import prescriptReducer from './modules/prescription/index';
const reducer = {
  auth: authReducer,
  message: messageReducer,
  patient: patientReducer,
  user: userReducer,
  header: headerReducer,
  material: materialReducer,
  treatment: treatmentReducer,
  prescription: prescriptReducer,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});
export default store;
