import api from "../axios/index";
import {setCurrentUser} from "../store/modules/auth";

export const getAllPages = (request) => {
    const {page = 1, pageSize = 10, keyword = "", activated = true} = request;
    const params = {};
    if (keyword) {
        params.filter = keyword;
    }
    params.activated = activated;
    if (pageSize) {
        params.pageSize = pageSize;
    }
    if (page) {
        params.page = page;
    }
    return api.get(`/users`, {
        params,
    });
};
export const createUser = (params) => {
    return api.post(`/users`, params);
};
export const updateUser = (params) => {
    const {id, body} = params;
    return api.put(`/users/${id}`, body);
};
export const resetPasswordApi = (id, password) => {
    return api.put("/users/reset_password/" + id, password);
};

export const getAuthorityList = () => {
    return api.get("/authority/list");
};

export const getUserInfoById = (id) => {
    return api.get(`/users/${id}`);
};

export const getCurrentUserInfo = () => {
    return api.get(`/users/info`);
};

export const changeBranch = (branchId) => {
    return api.put(`/users/switch_branch/${branchId}`);
};
export const changeActivated = (id) => {
    return api.put(`/users/change_active/${id}`);
};
export const getListDoctor = (role = []) => {
    const params = {};
    if (role.length > 0) {
        params.role = role.join(',');
    }
    return api.get('/users', {params});
};

export const checkRole = async (user, roleName) => {
    user = JSON.parse(localStorage.getItem('userInfo'));
    if (user == null) {
        user = await getCurrentUserInfo();
        localStorage.setItem('userInfo', JSON.stringify(user));
    }
    console.log(user.roles.includes(roleName));
    return user.roles.includes(roleName);
}

export const checkAnyRole = (roles, neededRoles) => {
    neededRoles.forEach((role) => {

    });
    for (let i = 0; i < neededRoles.length; i++) {
        let role = neededRoles[i];
        if (roles.includes(role)) return true;
    }
    return false;
}