import axios from "axios";
import https from "https-browserify";
import queryString from "query-string";
  export const API_URL = "https://core.nhakhoasmile.vn";
// export const API_URL = "http://localhost:8888";

const client = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
  baseURL: `${API_URL}/api`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    "Access-Control-Allow-Origin": "*",
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

client.interceptors.request.use(async (config) => {
  const customHeaders = {};

  // const accessToken = localStorage.getItem("Bearer");
  const accessToken = localStorage.getItem("Bearer");
  if (accessToken) {
    customHeaders.Authorization = accessToken;
  }

  return {
    ...config,
    headers: {
      ...customHeaders, // auto attach token
      ...config.headers, // but you can override for some requests
    },
  };
});

client.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  (error) => {
    // Handle errors
     if (error.response.status === 401) {
         localStorage.removeItem("Bearer");
         localStorage.removeItem("userInfo");
         window.location.reload();
     }
    if (error.response.status === 403 || error.response.status === 404) {
       localStorage.removeItem("Bearer");
     }
    throw error;
  }
);

export default client;
