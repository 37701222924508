import { createSlice } from "@reduxjs/toolkit";
import { getMaterialsAll,getMaterials } from "./actions";

const initialState = {
  records: [],
  materials:[],
  page: 1,
  total: 1,
  pageSize: 100,
  loading: false,
  searchLoading: false,
  triggerLoading: false,
  error: "",
  keyWord: "",
  categoryId: -1,
};

const materialSlice = createSlice({
  name: "material",
  initialState,
  reducers: {
    filterKeyword: (state, action) => {
      state.keyWord = action.payload;
    },
    setCategoryId: (state, action) => {
      state.categoryId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMaterialsAll.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMaterialsAll.fulfilled, (state, action) => {
        //console.log(action);
        state.loading = false;
        state.records = action.payload.records;
      })
      .addCase(getMaterialsAll.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getMaterials.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMaterials.fulfilled, (state, action) => {
        state.loading = false;
        state.materials = action.payload.records;
        state.page = action.payload.page;
        state.total = action.payload.totalElements;
      })
      .addCase(getMaterials.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

const { reducer, actions } = materialSlice;
export const {  filterKeyword,setCategoryId } = actions;
export default reducer;
