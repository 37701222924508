import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getMaterial,
  getMaterialCategories,
} from "../../../serviecs/material.serviec";
import { setMessage } from "../message/index";

export const getMaterialsAll = createAsyncThunk(
  "material/getList",
  async (request, thunkAPI) => {
    const { page } = request;
    try {
      const data = await getMaterialCategories(request);
      return {
        records: data || [],
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getMaterials = createAsyncThunk(
  "material/getMaterials",
  async (request, thunkAPI) => {
    const { page } = request;
    try {
      const { content, totalElements } = await getMaterial(request);
      return {
        records: content || [],
        page: page,
        totalElements: totalElements,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
