import api from '../axios/index';
import {getUnixTimeStamp} from 'serviecs/patient.serviec';

export const getTreatment = (id) => {
    return api.get(`/patients/${id}/treatments`);
};
export const createTreatment = (id, data) => {
    const body = {
        examineDate: getUnixTimeStamp(data),
    };
    return api.post(`/patients/${id}/treatments`, body);
};
export const updateTreatment = () => {
    return api.put(`/patients/`);
};
export const createTreatmentPlan = (id, data = []) => {
    const treatmentPlanItemForms = data.map((item) => {
        return {
            teethNo: item.teethNo,
            toothSignId: item.toothSignId,
            dueDate: getUnixTimeStamp(new Date()),
            quantity: Number(item.quantity),
            price: Number(item.price),
            discountAmount: Number(item.discountAmount),
            discountPercent: Number(item.discountPercent),
            amount: Number(item.amount),
            treatmentGroupItemId: item.id,
        };
    });
    return api.post(`/treatments/${id}/treatment_plans`, {
        treatmentPlanItemForms: treatmentPlanItemForms,
    });
};
export const updateTreatmentPlanGroupItem = (id, item = {}, treatmentId) => {
    const treatmentPlanItemForms = {
        teethNo: item.teethNo,
        toothSignId: item.toothSignId,
        quantity: Number(item.quantity),
        price: Number(item.price),
        discountAmount: Number(item.discountAmount),
        discountPercent: Number(item.discountPercent),
        amount: Number(item.amount),
        treatmentGroupItemId: item.treatmentGroupItemId,
        status: item.status,
        dueDate: item.dueDate,
        type: item.type
    };

    return api.put(
        `/treatments/${id}/treatment_plans/${treatmentId}/treatment_plan_items/${item.id}`,
        treatmentPlanItemForms
    );
};
export const updateTreatmentPlan = (patient, treatment, data) => {
    const body = {
        ...data,
        examineDate: getUnixTimeStamp(new Date()),
    };
    return api.put(`/patients/${patient}/treatments`, body);
};

export const treatmentGroups = () => {
    return api.get('/treatment_groups');
};
export const treatmentGroupItems = () => {
    return api.get('/treatment_group_items?searchText=""');
};
export const treatmentGroupItemsByName = (name) => {
    return api.get('/treatment_group_items?pageSize=10000&searchText=' + name);
};
export const treatmentGroupItem = (id) => {
    return api.get(`/treatment_group_items/${id}`);
};

export const getGalleries = (id, type = 0) => {
    if (type == '3') {
        return api.get(`/treatments/${id}/galleries`);
    }
    return api.get(`/treatments/${id}/galleries?type=${type}`);
};
export const createImage = (id, data = {}) => {
    return api.post(`/treatments/${id}/galleries`, data);
};
export const getAudit = (id) => {
    return api.get(`/patient_audits/${id}`);
};

export const getToothSigns = () => {
    return api.get('/tooth_signs');
};
/// treatmentplanitem
export const updateTreatmentPlanStatus = (treatmentId) => {
    return api.put(`/treatment_plan_items/${treatmentId}/status`);
};
export const deleteTreatmentPlanItem = (treatmentId) => {
    return api.delete(`/treatment_plan_items/${treatmentId}`);
};
// tiến trình điều trị
export const updateTreatmentProcessItem = (data) => {
    const {
        id: treatmentProcessItemId,
        amount,
        customerCareId,
        discountAmount,
        discountPercent,
        dueDate,
        price,
        quantity,
        status,
        teethNo,
        toothSignId,
        treatmentDoctorId,
        treatmentItem,
        note
    } = data;
    const body = {
        amount,
        customerCareId,
        discountAmount,
        discountPercent,
        dueDate: getUnixTimeStamp(dueDate),
        price,
        quantity,
        status: status === 'ACTIVE' ? 0 : 1,
        teethNo,
        toothSignId,
        treatmentDoctorId,
        treatmentItemId: treatmentItem.id,
        note : note
    };
    return api.put(`/treatment_process_items/${treatmentProcessItemId}`, body);
};
export const deleteTreatmentProcessItem = (treatmentProcessItemId) => {
    return api.delete(`/treatment_process_items/${treatmentProcessItemId}`);
};

export const rollbackTreatmentProcessItem = (treatmentProcessItemId) => {
    return api.put(`/treatment_process_items/${treatmentProcessItemId}/rollback`);
};

///commemt process item
export const getTreatmentProcessItemComments = (treatmentProcessItemId) => {
    return api.get(`/treatment_process_items/${treatmentProcessItemId}/comments`);
};
export const createTreatmentProcessItemComments = (
    treatmentProcessItemId,
    body
) => {
    return api.post(
        `/treatment_process_items/${treatmentProcessItemId}/comments`,
        body
    );
};

///PUT /api/treatment_comments/{treatmentProcessItemCommentId}
export const updateTreatmentProcessItemComments = (
    treatmentProcessItemCommentId,
    body
) => {
    return api.put(`/treatment_comments/${treatmentProcessItemCommentId}`, body);
};
export const deleteTreatmentProcessItemComments = (
    treatmentProcessItemCommentId
) => {
    return api.delete(`/treatment_comments/${treatmentProcessItemCommentId}`);
};
//prescription treatment
export const createTreatmentPrescription = (id, body) => {
    return api.post(`/treatments/${id}/prescriptions`, body);
};


export const createTreatmentGroup = (row) => {
    return api.post(`/treatment_groups`, row);
};

export const createTreatmentGroupItem = (row) => {
    row.discount = 0;
    return api.post(`/treatment_group_items`, row);
};

export const updateTreatmentGroupItem = (row) => {
    return api.put(`/treatment_group_items/${row.id}`, row);
};
export const updateTreatmentGroup = (row) => {
    return api.put(`/treatment_groups/${row.id}`, row);
};

export const deleteTreatmentGroup = (id) => {
    return api.delete(`/treatment_groups/${id}`);
};

export const deleteTreatmentGroupItem = (id) => {
    return api.delete(`/treatment_group_items/${id}`);
};