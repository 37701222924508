import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  prescriptionId: '',
  prescriptionName: '',
};
const prescriptionIdrSlice = createSlice({
  name: 'material',
  initialState,
  reducers: {
    setPrescriptionId: (state, action) => {
      state.prescriptionId = action.payload;
    },
    setPrescriptionName: (state, action) => {
      state.prescriptionName = action.payload;
    },
  },
});

const { reducer, actions } = prescriptionIdrSlice;
export const { setPrescriptionId, setPrescriptionName } = actions;
export default reducer;
