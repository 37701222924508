import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    title:'',
    filterOption: ''
};

const headerSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setTitleHeader: (state, action) => {
      return { title: action.payload };
    },
    setFilterOptions: (state, action) => {
      return { filterOption: action.payload };
    }
  },
});

const { reducer, actions } = headerSlice;
export const { setTitleHeader,setFilterOptions } = actions;
export default reducer;
