// import { useState } from "react";
import { Divider, Form, Input, Menu, Select } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
// import './Sidenav.scss';
import logo from '../../assets/images/logo-color.png';
import menuConfig from '../../config/menuConfig';
import React, { useEffect, useRef, useState } from 'react';
import { Space } from 'antd/es';
import Button from 'antd-button-color';
import { PlusOutlined } from '@ant-design/icons';
import api from '../../axios';
import {
  checkAnyRole,
  checkRole,
  getCurrentUserInfo,
} from '../../serviecs/user.serviec';
import moment from 'moment/moment';

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const [roles, setRoles] = useState([]);
  const [patientIdValue, setPatientIdValue] = useState();
  const page = pathname.replace('/', '');
  const inputRef = useRef();
  const [listPatient, setListPatient] = useState([]);
  let patientSearchValue = '';
  let timeout;
  const history = useHistory();
  const handleSearch = (newValue) => {
    if (newValue) {
      fetch(newValue, setListPatient());
    } else {
      setListPatient([]);
    }
  };
  const getRoles = async () => {
    const res = await getCurrentUserInfo();
    setRoles(res.roles);
  };

  useEffect(() => {
    getRoles();
  }, []);
  const fetch = (value, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    patientSearchValue = value;

    const callSearch = () => {
      let params = {};
      params.page = 1;
      params.pageSize = 10;
      params.keyword = patientSearchValue;
      api
        .get(`/patients/`, {
          params,
        })
        .then((data) => {
          const list = data.content.map((item) => ({
            value: item.id,
            label: item.pidStr + " - " + item.name  + (item.dob ? (", " + moment(item.dob).format("YYYY"))  : '')
             + (item.address ? "\n" +  item.address : ''),
            mobile: item.phone,
          }));
          setListPatient(list);
          //console.log(list);
        });
    };

    timeout = setTimeout(callSearch, 1000);
  };
  const  handleChange = (e) => {
    if (e != null) {
      history.push(`/patient-record-detail/${e}#basicInformation`);
    }
    setPatientIdValue(null);
  };
  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
      </div>
      <hr />
      {
        checkAnyRole(roles, ["patient:list:access"]) ?
            <Form.Item>
              <Select
                  dropdownClassName={'patient-search'}
                  showSearch
                  notFoundContent={'Không có kết quả'}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleSearch}
                  onFocus={() => {
                    fetch('', setListPatient());
                  }}
                  onChange={handleChange}
                  value={patientIdValue}
                  placeholder="Tìm kiếm khách hàng"
                  options={listPatient}
              />
            </Form.Item> : ''
      }

      <Menu theme="dark" mode="inline">
        {menuConfig.map((item, index) => {
          if (
            item.children &&
            item.children.length > 0 &&
            (item.roles.length === 0 || checkAnyRole(roles, item.roles))
          ) {
            return (
              <SubMenu
                key={item.title}
                title={
                  <div style={{ display: 'flex', alignItem: 'center' }}>
                    <span
                      className="icon"
                      style={{
                        background: page === item.path ? color : '',
                      }}
                    >
                      {item.icon}
                    </span>
                    <span className="label">{item.title}</span>
                  </div>
                }
              >
                {item.children.map((i) => {
                  if (i.roles.length === 0 || checkAnyRole(roles, i.roles))
                    return (
                      <Menu.Item key={i.title}>
                        {' '}
                        <NavLink to={i.path}>
                          <span className="label">{i.title}</span>
                        </NavLink>
                      </Menu.Item>
                    );
                })}
              </SubMenu>
            );
          } else if (item.roles.length === 0 || checkAnyRole(roles, item.roles))
            return (
              <Menu.Item key={index}>
                <NavLink to={item.path}>
                  <span
                    className="icon"
                    style={{
                      background: page === item.path ? color : '',
                    }}
                  >
                    {item.icon}
                  </span>
                  <span className="label">{item.title}</span>
                </NavLink>
              </Menu.Item>
            );
        })}
      </Menu>
    </>
  );
}

export default Sidenav;
