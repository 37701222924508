
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {Layout, Drawer, Affix, Input} from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header";
import Footer from "./Footer";

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children }) {
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [sidenavColor, setSidenavColor] = useState("#001529");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const openDrawer = () => setIsCollapsed(!isCollapsed);
  const handleSidenavType = (type) => setSidenavType(type);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  useEffect(() => {
    if (pathname === "rtl") {
      setPlacement("left");
    } else {
      setPlacement("right");
    }
  }, [pathname]);

  return (
    <Layout
      className={`layout-dashboard ${
        pathname === "profile" ? "layout-profile" : ""
      } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
    >
      <Sider
        breakpoint="xl"
        collapsedWidth="100"
        collapsed={isCollapsed}
        onCollapse={(collapsed, type) => {
          setIsCollapsed(collapsed);
        }}
        collapsible
        trigger={null}
        width={250}
        theme="dark"
        className={`sider-primary ant-layout-sider-primary ${
          sidenavType === "#fff" ? "active-route" : ""
        }`}
        style={{ background: sidenavType }}
      >
        <Sidenav color={sidenavColor} />
      </Sider>
      <Layout style={{marginLeft:isCollapsed ? "100px" : "250px"}}>
        <Affix>
          <AntHeader className={`${isCollapsed ? "ant-header-fixed" : ""}`}>
            <Header
                onPress={openDrawer}
            />
          </AntHeader>
        </Affix>
        <Content className="content-ant">{children}</Content>
      </Layout>
    </Layout>
  );
}

export default Main;
