import api from '../axios/index';
import moment from 'moment';
import { isEmpty } from 'lodash';
import {reformatDateFilter} from "../utils";
export function getUnixTimeStamp(date) {
  return moment(date, ['DD/MM/YYYY']).valueOf();
}
export const getAllPage = async (request) => {
  const { page = 1, pageSize = 5, keyword = '', listFilter = {} } = request;
  const params = {};
  if (keyword) {
    params.keyword = keyword;
  }
  if (pageSize) {
    params.pageSize = pageSize;
  }
  if (page) {
    params.page = page;
  }
  if (!isEmpty(listFilter)) {
    const {
      created = [],
      date = [],
      treatmentDate = [],
      doctor = '',
      patientGroupId = '',
      patientSourceId = '',
      treatmentGroup = '',
      treatmentStatus = null,
    } = listFilter;
    if (!isEmpty(created)) {
      params.fromCreatedDate = getUnixTimeStamp(created[0]);
      params.toCreatedDate = getUnixTimeStamp(created[1]);
    }
    if (!isEmpty(date)) {
      params.fromDate = getUnixTimeStamp(date[0]);
      params.toDate = getUnixTimeStamp(date[1]);
    }
    if (!isEmpty(treatmentDate)) {
      params.fromTreatmentDate = getUnixTimeStamp(treatmentDate[0]);
      params.toTreatmentDate = getUnixTimeStamp(treatmentDate[1]);
    }
    if (doctor) {
      params.doctor = doctor;
    }
    if (patientGroupId) {
      params.patientGroupId = patientGroupId;
    }
    if (patientSourceId) {
      params.patientSourceId = patientSourceId;
    }
    if (treatmentGroup) {
      params.treatmentGroup = treatmentGroup;
    }
    if (treatmentStatus === true) {
      params.treatmentStatus = treatmentStatus;
    }
    if (treatmentStatus === false) {
      params.treatmentStatus = treatmentStatus;
    }
  }
  return api.get(`/patients/`, {
    params,
  });
};

export const searchPatientByName = (filter) => {
  let params = {};
  params.page = 1;
  params.pageSize = 10;
  params.keyword = filter;
  return api.get(`/patients/`, {
    params,
  });
};
export const createPatient = (params) => {
  return api.post(`/patients`, params);
};
export const getPatientId = (id) => {
  return api.get(`/patients/${id}`);
};
export const updatePatient = (id, params) => {
  const data = {
    ...params,
    id: id,
  };
  return api.put(`/patients`, data);
};

export const getPatientSource = () => {
  return api.get(`/patient_sources`);
};
export const getPatientGroup = () => {
  return api.get('/patient_groups');
};
export const getPatientPromotion = () => {
  return api.get('/promotion_groups');
};
export const getBranches = () => {
  return api.get('/branches');
};

export const getBranch = (id) => {
  return api.get(`/branches/${id}`);
};
export const updateBranches = (id, params) => {
  return api.put(`/branches/${id}`, params);
};
///treatment_groups
export const getTreatmentGroups = () => {
  return api.get('/treatment_groups');
};

export const getTreatmentGroupsItems = () => {
  return api.get('/treatment_group_items?searchText=""');
};

/// treatments info
export const getTreatmentInfo = (idPatient) => {
  return api.get(`/patients/${idPatient}/treatments`);
};

export const createTreatmentInfo = (idPatient) => {
  return api.post(`/patients/${idPatient}/treatments`);
};

export const getPatientPayment = (id) => {
  return api.get(`/patients/${id}/payment`);
};

export const updatePatientInstallment = (id, amount, month, startDate) => {
  return api.put(`/patients/${id}/installment`, {
    amount, month, startDate
  });
};


export const deleteReceiptReq = (id) => {
  return api.delete(`/payment/receipts/${id}`);
};
export const getPatientPaymentItems = (idPatient, treatmentId) => {
  return api.get(`/patients/${idPatient}/treatmentPlan/${treatmentId}`);
};
// trả góp
export const getPatientPaymentInstallment = (idPatient, treatmeantId) => {
  return api.get(
    `patients/${idPatient}/treatmentPlan/${treatmeantId}/installment`
  );
};

export const getInstallmentRes = (installmentId) => {
  return api.get(
    `patients/installment/${installmentId}`
  );
};
export const updateTreatmentInfo = (idPatient, params) => {
  return api.put(`/patients/${idPatient}/treatments`, params);
};

export const deleteTreatmentInfo = (idPatient, idTreatment) => {
  return api.delete(`/patients/${idPatient}/treatments/${idTreatment}`);
};
/// treatment plans to treatment
export const createTreatmentsPlans = (idPatient) => {
  return api.post(`/patients/${idPatient}/treatmentsPlans`);
};
export const getListDoctor = (role = []) => {
  const params = {};
  if (role.length > 0) {
    params.role = role.join(',');
  }
  return api.get('/doctors', { params });
};
export const getDoctorById = (id) => {
  return api.get(`/doctors/${id}`);
};
export const getListUser = (role = []) => {
  const params = {};
  if (role.length > 0) {
    params.role = role.join(',');
    params.activated = true;
  }
  return api.get('/users', { params });
};
//đơn thuốc khách hàng
export const getPatientPrescription = (id) => {
  return api.get(`/patients/${id}/prescriptions`);
};
export const existBrace = (id) => {
  return api.get(`/braces/${id}/checkExisted`);
};

export const existImplant = (id) => {
  return api.get(`/implants/${id}/checkExisted`);
};

export const getPatientAppointment = async (request) => {
  let {id, clazz = null,  page = 1, pageSize = 10, dateType = 1 , date = [moment().startOf('month'), moment().endOf('month')]} = request;
  const params = {};
  if (page) params.page = page;
  if (pageSize) params.pageSize = pageSize;
  const dateFilter = reformatDateFilter({date, dateType});
  params.fromDate = dateFilter.fromDate;
  params.toDate = dateFilter.toDate;
  params.patientId = id;
  if (clazz) {
    params.clazz = clazz;
  }
  return api.get(`/appointments`, { params });
};


export const getAppointmentGroupByPatient = async (request) => {
  let {clazz,  page = 1, pageSize = 10, dateType = 1 , date = [moment().startOf('month'), moment().endOf('month')], implantDateType = "0", status = null, search = null} = request;
  const params = {};
  if (page) params.page = page;
  if (pageSize) params.pageSize = pageSize;
  const dateFilter = reformatDateFilter({date, dateType});
  params.fromDate = dateFilter.fromDate;
  if (search != null) {
    params.search = search;
  }
  params.toDate = dateFilter.toDate;
  params.clazz = clazz ? clazz : 'BASIC';
  if (clazz === 'IMPLANT') {
    params.dateType = implantDateType;
    if (status) {
      params.implantStatus = status;
    }
  } else if (clazz === 'BRACE') {
    if (status) {
      params.braceStatus = status;
    }
  }
  return api.get(`/appointments/groups`, { params });
}


export const deleteAppointmentGroup = async (id) => {
  return api.delete(`/appointments/groups/${id}`);
};
export const getAppointmentBookTypes = async () => {
  return api.get(`/appointments/book_types`);
};

export const getMinutesList = async () => {
  return api.get(`/appointments/minutes`);
};

export const getBraceTechnical = (async) => {
  return api.get(`/braces/techniques`);
};

export const getImplantTechnical = async  => {
  return api.get(`/implants/techniques`);
}

export const getPaymentTags = async  => {
  return api.get(`/patients/payment_tags`);
}


export const getTreatmentTags = async  => {
  return api.get(`/patients/treatment_tags`);
}

export const getBraceStatusList = (async) => {
  return api.get(`/braces/status`);
};

export const getImplantStatusList = (async) => {
  return api.get(`/implants/status`);
};

export const quickCallForBrace = (id) => {
  return api.put(`/patients/quick_call/${id}/brace`);
};

export const quickCallForImplant = (id) => {
  return api.put(`/patients/quick_call/${id}/implant`);
};

export const getTreatmentPlanTypes  =() => {
  return api.get(`/treatments/treatment_plan_item_types`)
}

export const getCommissionSumRes  =(id) => {
  return api.get(`/commissions/patient/${id}`)
}