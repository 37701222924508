import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import routeList from './config/routeMap';
import SignIn from './pages/SignIn';
import Main from './components/layout/Main';
import 'antd/dist/antd.css';

//import "./assets/styles/main.css";
import './assets/styles/main.scss';
import './assets/styles/responsive.css';
import 'antd-button-color/dist/css/style.css';

function App() {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);
  useEffect(() => {}, [isLoggedIn, dispatch]);
  return (
    <div className="App">
      <Switch>
        <Route path="/sign-in" exact component={SignIn} />
        <Route
          path="/"
          render={() => {
            if (!isLoggedIn) {
              return <Redirect to="/sign-in" />;
            } else {
              return (
                <Main>
                  {routeList.map((item) => {
                    return (
                      <Route
                        exact
                        path={item.path}
                        component={item.component}
                      />
                    );
                  })}
                  {/* <Redirect from="*" to="/patients" /> */}
                </Main>
              );
            }
          }}
        />
      </Switch>
    </div>
  );
}

export default App;
