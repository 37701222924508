import { createSlice } from "@reduxjs/toolkit";
import { getList } from "./actions";
const initialState = {
  record: {},
  loading: false,
  teethNo: [],
};
const treatmentSlice = createSlice({
  name: "treatment",
  initialState,
  reducers: {
    setTeethNo: (state, action) => {
      state.teethNo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.loading = false;
        state.record = action.payload.record;
      })
      .addCase(getList.rejected, (state, action) => {
        state.loading = false;
        state.record = {};
      });
  },
});

const { reducer, actions } = treatmentSlice;
export const { setTeethNo } = actions;

export default reducer;
