import api from "../axios/index";
export const getMaterialCategories = () => {
  return api.get("/material_categories");
};

export const deleteMaterialCategory = (id) => {
  return api.delete(`/material_categories/${id}`);
};

export const getMaterial = async (request) => {
  const { page = 1, pageSize = 10, keyWord = "", categoryId = null, reportType = null } = request;
  const params = {};
  if (keyWord) {
    params.keyWord = keyWord;
  }
  if (pageSize) {
    params.pageSize = pageSize;
  }
  if (page) {
    params.page = page;
  }
  if (categoryId) {
    params.categoryId = categoryId;
  }
  if (categoryId) {
    params.reportType = reportType;
  }
  return api.get("/material_dental_supplies", {
    params,
  });
};

export const getMaterialReport = async (request) => {
  console.log(request);
  const { page = 0, pageSize = 10, keyWord = "", categoryId = null, reportType = null } = request;
  const params = {};
  if (keyWord) {
    params.keyWord = keyWord;
  }
  if (pageSize) {
    params.pageSize = pageSize;
  }
  if (page) {
    params.page = Math.max(0,page - 1);
  }
  if (categoryId) {
    params.categoryId = categoryId;
  }
  if (reportType) {
    params.reportType = reportType;
  }
  return api.get("/material_dental_supplies/report", {
    params,
  });
};

export const getReportTypes = async () => {
  return api.get("/material_dental_supplies/report/types")
}


export const postMaterialDentalSupply = async (request) =>{
  return api.post("/material_dental_supplies", request);
}

export const updateMaterialDentalSupply = async (id,request) =>{
  return api.put(`/material_dental_supplies/${id}`, request);
}

export const deleteMaterialDentalSupply = async (id) =>{
  return api.delete(`/material_dental_supplies/${id}`);
}