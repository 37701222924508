import { createSlice } from "@reduxjs/toolkit";
import { getList, createUsers, editUsers  } from "./actions";

const initialState = {
  records: [],
  record: {},
  page: 1,
  total: 1,
  pageSize: 10,
  loading: false,
  searchLoading: false,
  isShowForm: false,
  triggerLoading: false,
  error: "",
  keyword: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    editUser: (state, action) => {
      state.record = action.payload;
      state.isShowForm = true;
    },
    addUser: (state, action) => {
      state.isShowForm = true;
    },
    closeForm: (state, action) => {
      state.isShowForm = false;
      state.record = {};
    },
    filterKeyword: (state, action) => {
      state.keyword = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload.records;
        state.isShowForm = false;
        state.page = action.payload.page;
        state.total = action.payload.totalElements;
        state.pageSize = action.payload.size;
      })
      .addCase(createUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.triggerLoading = !state.triggerLoading;
        state.record = {};
      })
      .addCase(createUsers.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(editUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(editUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.triggerLoading = !state.triggerLoading;
        state.record = {};
      })
      .addCase(editUsers.rejected, (state, action) => {
        state.loading = false;
      })
  },
});

const { reducer, actions } = userSlice;
export const { editUser, addUser, closeForm ,filterKeyword } = actions;
export default reducer;
