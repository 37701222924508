import Loadable from 'react-loadable';
import Loading from '../components/Loading';

const PatientDetail = Loadable({
  loader: () => import('../pages/patient-detail/index'),
  loading: Loading,
});
const Home = Loadable({
  loader: () => import('../pages/home/index'),
  loading: Loading,
});
const Patient = Loadable({
  loader: () => import('../pages/patient/index'),
  loading: Loading,
});
const User = Loadable({
  loader: () => import('../pages/user/index'),
  loading: Loading,
});
const Meterial = Loadable({
  loader: () => import('../pages/material/index'),
  loading: Loading,
});
const Medicine = Loadable({
  loader: () => import('../pages/setting/medicine/index'),
  loading: Loading,
});
const Appointment = Loadable({
  loader: () => import('../pages/appointment/index'),
  loading: Loading,
});
const BraceManage = Loadable({
  loader: () => import('../pages/appointment/BraceManage'),
  loading: Loading,
});
const ImplantManage = Loadable({
  loader: () => import('../pages/appointment/ImplantManage'),
  loading: Loading,
});
const TrickSetting = Loadable({
  loader: () => import('../pages/setting/trick/index'),
  loading: Loading,
});
const WorkshopSetting = Loadable({
  loader: () => import('../pages/setting/workshop/index'),
  loading: Loading,
});
const LabCeramic = Loadable({
  loader: () => import('../pages/workshop/ceramic/index'),
  loading: Loading,
});
const LabAssembly = Loadable({
  loader: () => import('../pages/workshop/assembly/index'),
  loading: Loading,
});

const PushSaleSettingComponents = Loadable({
  loader: () => import('../pages/push-sale-setting/PushSaleComponents'),
  loading: Loading,
});
const PushSaleSettingFlow = Loadable({
  loader: () => import('../pages/push-sale-setting/PushSaleFlow'),
  loading: Loading,
});

const MaterialImex = Loadable({
  loader: () => import('../pages/material-imex'),
  loading: Loading,
});

const MaterialSupplyReport = Loadable({
  loader: () => import('../pages/material-supply-report'),
  loading: Loading,
});
const SettingGeneral = Loadable({
  loader: () => import('../pages/setting/general'),
  loading: Loading,
});

const PushSale = Loadable({
  loader: () => import('../pages/push-sale'),
  loading: Loading,
});

const Commission = Loadable({
  loader: () => import('../pages/commission'),
  loading: Loading,
});

const ReportRevenue = Loadable({
  loader: () => import('../pages/report/revenue/index'),
  loading: Loading,
});

const ActualIncome = Loadable({
  loader: () => import('../pages/report/actual-income/index'),
  loading: Loading,
});

const OwedStatistical = Loadable({
  loader: () => import('../pages/report/owed-statistical/index'),
  loading: Loading,
});

const InstallmentReport = Loadable({
  loader: () => import('../pages/report/installment-report/index'),
  loading: Loading,
});

const Permissions = Loadable({
  loader: () => import('../pages/setting/permissions/index'),
  loading: Loading,
});
//set up routers
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: '/setting/material-dental-supply',
    component: Meterial,
  },
  {
    path: '/setting/medicine',
    component: Medicine,
  },
  {
    path: '/setting/trick',
    component: TrickSetting,
  },
  {
    path: '/patient-record-detail/:id',
    component: PatientDetail,
  },
  {
    path: '/patient',
    component: Patient,
  },
  {
    path: '/user',
    component: User,
  },
  {
    path: '/appointment',
    component: Appointment,
  },
  {
    path: '/brace-appointment',
    component: BraceManage,
  },
  {
    path: '/implant-appointment',
    component: ImplantManage,
  },
  {
    path: '/home',
    component: Home,
  },
  {
    path: '/workshop/ceramic',
    component: LabCeramic,
  },
  {
    path: '/workshop/assembly',
    component: LabAssembly,
  },
  {
    path: '/setting/pushsale/components',
    component: PushSaleSettingComponents,
  },
  {
    path: '/setting/pushsale/flow',
    component: PushSaleSettingFlow,
  },
  {
    path: '/material-dental-imex',
    component: MaterialImex,
  },
  {
    path: '/material-supply-report',
    component: MaterialSupplyReport,
  },
  {
    path: '/setting/general',
    component: SettingGeneral,
  },
  {
    path: '/setting/workshop',
    component: WorkshopSetting,
  },
  {
    path: '/customer-care',
    component: PushSale,
  },
  {
    path: '/commission',
    component: Commission,
  },
  {
    path: '/revenue-statistical',
    component: ReportRevenue,
  },
  {
    path: '/revenue-expenditure',
    component: ActualIncome,
  },
  {
    path: '/owed-statistical',
    component: OwedStatistical,
  },
  {
    path: '/installment-report',
    component: InstallmentReport,
  },
  {
    path: '/permissions',
    component: Permissions,
  },
];
